import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:("fill-height " + _vm.backgroundColor),attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10","md":"4"}},[_c(VCard,{style:(("border-color: " + _vm.borderColor + "; border-width: " + _vm.borderSize + "px; border-radius: 5px;"))},[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":"","height":"90"}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"width":"100%"}},[_c('img',{staticClass:"mx-1",attrs:{"src":"/name.svg","height":"60"}}),_c('img',{staticClass:"mx-1",attrs:{"src":"/logo.svg","height":"60"}})])]),_c(VCardText,{staticClass:"pt-8"},[_vm._t("default")],2),_c(VCardActions,[_vm._t("actions")],2)],1)],1)],1),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('copyright',{attrs:{"size":64}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }