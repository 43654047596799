<template>
  <v-container :class="`fill-height ${backgroundColor}`" fluid>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="10" md="4">
        <v-card :style="`border-color: ${borderColor}; border-width: ${borderSize}px; border-radius: 5px;`">
          <v-toolbar color="primary" dark flat height="90">
            <div
              class="d-flex align-center justify-center"
              style="width: 100%;"
            >
              <img src="/name.svg" height="60" class="mx-1" />
              <img src="/logo.svg" height="60" class="mx-1" />
            </div>
          </v-toolbar>
          <v-card-text class="pt-8">
            <slot></slot>
          </v-card-text>
          <v-card-actions>
            <slot name="actions"></slot>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <copyright :size="64" />
    </v-row>
  </v-container>
</template>

<script>
import Copyright from "@/common/components/Copyright";

export default {
  name: "CenterScreen",
  components: { Copyright },
  props: {
    backgroundColor: {
      type: String,
      default() {
        return "secondary";
      }
    },
    borderSize: {
      type: Number,
      default() {
        return 4;
      }
    },
    borderColor: {
      type: String,
      default() {
        return "#fff";
      }
    }
  },
};
</script>
